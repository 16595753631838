import React from "react"
import { graphql } from 'gatsby'
import { Image } from "theme-ui"
import ProductTemplate from "../../components/store/ProductTemplate"
import BuyButton from "../../components/store/BuyButton"

export const query = graphql`
  query {
    contentfulProduct(slug: { eq: "treasure-catalog-digital-edition" }) {
      status
      name
      slug
      price
      title
      images { file { url } }
      description { json }
      componentId
      productId
      relatedProducts {
        slug
      }
    }
  }
`

const ProductMagicPin = props => {
  return (
    <ProductTemplate
      name={props.data.contentfulProduct.name}
      slug={props.data.contentfulProduct.slug}
      status={props.data.contentfulProduct.status}
      title={props.data.contentfulProduct.title}
      price={props.data.contentfulProduct.price}
      description={props.data.contentfulProduct.description.json}
      images={
        props.data.contentfulProduct.images.map((image, i) =>
          <Image src={props.data.contentfulProduct.images[i].file.url} key={i} />
        )
      }
      buyButton={
        <BuyButton componentId={props.data.contentfulProduct.componentId} productId={props.data.contentfulProduct.productId} price={props.data.contentfulProduct.price} />
      }
      relatedProducts={
        props.data.contentfulProduct.relatedProducts.map((product, i) => 
          props.data.contentfulProduct.relatedProducts[i].slug
        )
      }
    />
  )
}

export default ProductMagicPin